<template>
  <div id="app">
<!--    <img alt="Vue logo" src="./assets/logo.png">-->
<!--    <p v-text="info"></p>-->

    <b-container>
      <b-row>
        <b-col>
          <b-card bg-variant="success" text-variant="white" header="écran en ligne" class="text-center">
            <b-card-text v-html="this.quimarche"></b-card-text>

          </b-card>

          <b></b>

        </b-col>

        <b-col>
          <b-card bg-variant="danger" text-variant="white" header="écran ne répondant plus" class="text-center">
            <b-card-text v-html="this.quimarchepas"></b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-container>


  </div>
</template>

<script>

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

export default {
  name: 'App',
  components: {
  },

  data (){
    return{
      info: null,
      quimarche: null,
      quimarchepas: null,
      stringtmp: null,
    }
  },
  methods: {
    getlist() {
      const axios = require('axios');


        console.log("hey")

        axios.get('https://monitoring.kisdis.com:4007')
            .then(response => {
              console.log("Reponse", response);
              this.info = response.data;

//              this.quimarche = response.data[0] + response.data[1]+  "</br>" + response.data[3] + response.data[4] +  "</br>" +
  //                response.data[6] + response.data[7] + "</br>" + response.data[9] + response.data[10] +"</br>"+ response.data[12]+response.data[13] ;

              this.quimarche = ""
              for ( let x =0 ; x < response.data.length ; x+=3)
              {
                console.log("c'estfou" , x)
                this.stringtmp = ""
                this.quimarche+= response.data[x] +response.data[x+1] + "_______________________________</br>" ;

                this.stringtmp = "_______________________________</br>" + response.data[x] ;
                response.data[x] = this.stringtmp;
              }
              this.quimarche =this.quimarche.replace(',','')

              this.quimarchepas = ""
              let z = 0


              console.log( response.data.length - 3 )
              for ( let x =0 ; x < response.data.length ; x+=3)
              {

                  for (let i =0; i < response.data[x+2].length;i++)
                  {

                    if (Array.isArray(response.data[x+2][i])== false && z ===0)
                    {
                      console.log(Array.isArray(response.data[x][i]), "c'est un array a cette adresse", i ,response.data[x])
                      this.quimarchepas+= response.data[x] +response.data[x+2]
                      z++
                      console.log("sa ne passe jamais")
                    }

                  }
                  z = 0
              } /*
              if (Array.isArray(response.data[2][0]) == false)
              {
                this.quimarchepas +=  response.data[0] + response.data[2]

              }
              if (Array.isArray(response.data[5][0]) == false)
              {
                this.quimarchepas +=  response.data[3] + response.data[5]

              }
              if (Array.isArray(response.data[8][0]) == false)
              {
                this.quimarchepas +=  response.data[6] + response.data[8]
              }
              if (Array.isArray(response.data[11][0]) == false)
              {
                this.quimarchepas +=  response.data[9] + response.data[11]
              }
              if (Array.isArray(response.data[14]) == false)
              {
                this.quimarchepas +=  response.data[12] + response.data[14]
              }*/
 //             this.quimarchepas +=  response.data
              this.quimarchepas =this.quimarchepas.replace(',','')

            })
            .catch(error => {
              console.log("error is", error)
            });
    },

    Forcerender() {

      console.log("mince")
        // Remove my-component from the DOM
        this.renderComponent = false;

        this.$nextTick(() => {
          console.log("ee oo")
          // Add the component back in
          this.renderComponent = true;
        });

      }


  },

  mounted() {
    this.getlist();
    this.Forcerender();
    this.timer = setInterval(this.getlist, 60000);
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

</style>
